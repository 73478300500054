import { Show, splitProps } from 'solid-js';
import { twMerge } from '@troon/tailwind-preset/merge';
import type { IconProps } from '../props';

export function IconMobileButton(props: IconProps) {
	const [, svgProps] = splitProps(props, ['title']);

	return (
		<svg
			viewBox="0 0 24 24"
			height="1.2em"
			width="1.2em"
			fill="none"
			stroke="none"
			{...svgProps}
			class={twMerge('inline-flex', svgProps.class)}
			aria-hidden={props.title ? 'false' : 'true'}
			xmlns="http://www.w3.org/2000/svg"
		>
			<Show when={props.title}>
				<title>{props.title}</title>
			</Show>
			<path
				fill="none"
				stroke="currentColor"
				stroke-linecap="round"
				stroke-linejoin="round"
				stroke-width="2"
				d="M7 6.2v11.6c0 1.12 0 1.68.218 2.108a2 2 0 0 0 .874.874c.427.218.987.218 2.105.218h3.606c1.118 0 1.677 0 2.104-.218c.377-.192.683-.498.875-.874c.218-.428.218-.986.218-2.104V6.197c0-1.118 0-1.678-.218-2.105a2 2 0 0 0-.875-.874C15.48 3 14.92 3 13.8 3h-3.6c-1.12 0-1.68 0-2.108.218a2 2 0 0 0-.874.874C7 4.52 7 5.08 7 6.2"
			/>
		</svg>
	);
}
